import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ScrollToTop from "utils/scrollToTop";

// pages
import { ThemeProvider } from "@mui/material";
import NotFound from "pages/404";
import { About } from "pages/about";
import { CaseStudy } from "pages/caseStudy";
import { Contact } from "pages/contact";
import Home from "pages/home";
import { Platform } from "pages/platform";
import PrivacyPolicy from "pages/privacyPolicy";
import { SuccessStories } from "pages/successStories";
import TermsAndConditions from "pages/termsAndConditions";
import { theme } from "utils/theme";

export default function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/success-story" element={<SuccessStories />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/platform" element={<Platform />} />
          <Route path="/case-study" element={<CaseStudy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer hideProgressBar="true" />
      </ThemeProvider>
    </>
  );
}
