import { HiCloud } from "react-icons/hi";

import { SiMaterialdesignicons } from "react-icons/si";
import { MdDeveloperMode } from "react-icons/md";

const features = [
  {
    name: "Communication Tools",
    description:
      "Our platform provides a cohesive space for effective player communication, eliminating the hurdles faced in player development.",
    icon: HiCloud,
  },
  {
    name: "Development Tools",
    description:
      "Coaches and players now have access to cutting-edge apps and software, empowering them in their development journey.",
    icon: MdDeveloperMode,
  },
  {
    name: "Player Disengagement",
    description:
      "Through our user-friendly interface, players stay engaged even during the off-season, fostering continuous growth.",
    icon: SiMaterialdesignicons,
  },
];

export function FeaturesNew() {
  return (
    <div className="bg-white py-24 sm:pt-32">
      <div className="mx-auto max-w-7xl px-4 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Efficient Way to Manage{" "}
            <span className="text-primary font-bold">Teams</span>
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We aim to enhance player performance while fostering a supportive
            environment within our Clevernation player platform.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-primary">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  {/* <p className="mt-6">
                    <a
                      href={feature.href}
                      className="text-sm font-semibold leading-6 text-primary">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
