import Layout from "fragments/layout/layout";
import { HeroPlatform } from "./components/hero";
import { TacklingDown } from "./components/tacklingDown";
import { FeaturesPlatform } from "./components/featurePlatform";
import { Incentives } from './components/incentive';

export function Platform() {
  return (
    <Layout title="Our Platform">
      <HeroPlatform />
      <TacklingDown />
      <FeaturesPlatform />
    <Incentives />
    </Layout>
  );
}
