export default function TestsAndStats() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div className="lg:pr-4">
          <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="/media/stadium22.jpeg"
              alt=""
            />
          </div>
        </div>
        <div>
          <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
            {/* <p className="text-base font-semibold leading-7 text-primary">
              Company values
            </p> */}
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our <span className="text-primary">Mission</span>
            </h1>
            <div className="max-w-xl">
              <p className="mt-6">
                At Clevernation, we believe that every athlete has the potential
                to achieve greatness. Our mission is to empower athletes at
                every stage of their journey to reach their full potential
                through collaborative development.
              </p>
              <h3 className="flex mt-6 text-primary items-center gap-x-3 text-lg font-semibold leading-7">
                A Unified Ecosystem of Development
              </h3>
              <p className="mt-2">
                We partner with sports associations, clubs, and academies to
                create a seamless development path that aligns with each
                athlete's unique goals and aspirations. Our collaborative
                approach extends beyond providing resources; it's an effort to
                help athletes take control of their journey and achieve their
                dreams.
              </p>
              <h3 className="flex text-primary items-center mt-6 text-lg font-semibold leading-7">
                Empowering Athletes to Take Control
              </h3>
              <p className="mt-2">
                Athletes should control their development, steering towards
                their aspirations and potential. By providing athletes with a
                personalised development plan, continuous support, and
                assessment, we empower them to take control of their journey and
                achieve greatness.
              </p>
              <p className="mt-2">
                Join us in empowering athletes to reach their full potential
                through collaborative development. Let's create a unified
                ecosystem of development that inspires athletes to become the
                best they can be. Together, we can make a difference in the
                world of sports.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
