import Layout from "fragments/layout/layout";
import { Hero } from "./components/hero";
import { TrustedBy } from "./components/trustedBy";
import { PrimaryFeatures } from "./components/features";
import { Stats } from "./components/stats";
import { SecondaryFeatures } from "./components/secondaryFeatures";
import { CTA } from "./components/cta";
import { FeaturesNew } from "./components/features1";

export default function Home() {
  return (
    <Layout>
      <div className="w-full flex justify-center  bg-wite">
        <div className="flex flex-col gap-14 w-full">
          <Hero />
          {/* <TrustedBy /> */}
          <FeaturesNew />
          <PrimaryFeatures />
          {/* <Stats /> */}
          <SecondaryFeatures />
          <CTA />
        </div>
      </div>
    </Layout>
  );
}
