import { Container } from "components/container";
import Layout from "fragments/layout/layout";
import { classNames } from "utils";

const featuredTestimonial = {
  body: `Our team's performance has skyrocketed since we started using Clevernation. The
  personalised training plans and analytics have given us a strategic edge. I can
  see the improvement in every player, and it's truly remarkable!`,
  author: {
    name: "John D.",
    handle: "Club Coach",
    // imageUrl:""
  },
};

const testimonials = [
  [
    [
      {
        body: `As a parent, seeing my child's growth on and off the field is priceless. Clevernation has not only enhanced their skills but also instilled discipline and teamwork. It's like a virtual coach and mentor all in one!`,
        author: {
          name: "Sarah M.",
          handle: "Parent",
          // imageUrl:""
        },
      },
      {
        body: `Clevernation is a game-changer! The drills, exercises, and insights into my performance have taken my game to a whole new level. It's like having a personal trainer 24/7, and I love the competitive edge it gives me.`,
        author: {
          name: "Chris B.",
          handle: "Player",
          // imageUrl:""
        },
      },
      {
        body: `I'm not a professional player, but I love honing my skills, and Clevernation has become my go-to training companion. The diverse range of drills and the ability to customise my training plan keep me motivated. It's like having a personal coach in my pocket!`,
        author: {
          name: "Olivia K.",
          handle: "Skill Development Enthusiast",
          // imageUrl:""
        },
      },
    ],
    [
      {
        body: `Managing a football team comes with its challenges, but Clevernation has made my job so much easier. The progress tracking and communication features streamline the entire process. Our team is more cohesive and focused than ever before.`,
        author: {
          name: "Emily P.",
          handle: "Team Manager",
          // imageUrl:""
        },
      },
      {
        body: `For high school teams aspiring to compete at the highest level, Clevernation is a must. It's comprehensive, easy to use, and the video analysis tools are a game-changer. My players are more engaged in training, and it's reflecting positively on our results.`,
        author: {
          name: "David R.",
          handle: "High School Coach",
          // imageUrl:""
        },
      },
    ],
  ],
  [
    [
      {
        body: `Even as a community club player, I've found immense value in Clevernation. The skill-building exercises are fun, and the platform's user-friendly interface makes it accessible for players of all levels. It's rekindled my love for the game.`,
        author: {
          name: "Alex F.",
          handle: "Community Club Player",
          // imageUrl:""
        },
      },
      {
        body: `Transitioning to academy level play was challenging, but Clevernation has been my secret weapon. The targeted workouts and position-specific drills have given me the confidence to excel on the field. I can't imagine my training routine without it.`,
        author: {
          name: "Natalie S.",
          handle: "Academy Player",
          // imageUrl:""
        },
      },
    ],
    [
      {
        body: `As a parent of a youth team player, safety and development are my top priorities. Clevernation not only provides top-notch training but also emphasises injury prevention. I appreciate the holistic approach to player well-being.`,
        author: {
          name: "Mike H.",
          handle: "Youth Team Parent",
          // imageUrl:""
        },
      },
      {
        body: `Leading a pathway team requires dedication and strategy. Clevernation has been a captain's dream. It helps me track individual progress, address weaknesses, and ensure my team is always in top form. Our league standings have never looked better!`,
        author: {
          name: "Carlos G.",
          handle: "Team Captain",
          // imageUrl:""
        },
      },
    ],
  ],
];

export function SuccessStories() {
  return (
    <Layout title="Success Stories">
      <Container>
        <div className="relative isolate bg-white pb-32 pt-24 sm:pt-32">
          <div
            className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
            aria-hidden="true">
            <div
              className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-primary to-primary"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
            aria-hidden="true">
            <div
              className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-primary to-primary xl:ml-0 xl:mr-[calc(50%-12rem)]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-7xl ">
            <div className="mx-auto max-w-xl text-center">
              <h2 className="text-lg font-semibold leading-8 tracking-tight text-primary">
                Testimonials
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                We have worked with hundreds of
                <span className="text-primary font-bold"> Amazing Teams</span>
              </p>
            </div>
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
              <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
                <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
                  <p>{`“${featuredTestimonial.body}”`}</p>
                </blockquote>
                <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
                  {/* <img
                    className="h-10 w-10 flex-none rounded-full bg-gray-50"
                    src={featuredTestimonial.author.imageUrl}
                    alt=""
                  /> */}
                  <div className="flex-auto">
                    <div className="font-semibold">
                      {featuredTestimonial.author.name}
                    </div>
                    <div className="text-gray-600">{`${featuredTestimonial.author.handle}`}</div>
                  </div>
                  {/* <img
                    className="h-10 w-auto flex-none"
                    src={featuredTestimonial.author.logoUrl}
                    alt=""
                  /> */}
                </figcaption>
              </figure>
              {testimonials.map((columnGroup, columnGroupIdx) => (
                <div
                  key={columnGroupIdx}
                  className="space-y-8 xl:contents xl:space-y-0">
                  {columnGroup.map((column, columnIdx) => (
                    <div
                      key={columnIdx}
                      className={classNames(
                        (columnGroupIdx === 0 && columnIdx === 0) ||
                          (columnGroupIdx === testimonials.length - 1 &&
                            columnIdx === columnGroup.length - 1)
                          ? "xl:row-span-2"
                          : "xl:row-start-1",
                        "space-y-8"
                      )}>
                      {column.map((testimonial) => (
                        <figure
                          key={testimonial.author.handle}
                          className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
                          <blockquote className="text-gray-900">
                            <p>{`“${testimonial.body}”`}</p>
                          </blockquote>
                          <figcaption className="mt-6 flex items-center gap-x-4">
                            {/* <img
                              className="h-10 w-10 rounded-full bg-gray-50"
                              src={testimonial.author.imageUrl}
                              alt=""
                            /> */}
                            <div>
                              <div className="font-semibold">
                                {testimonial.author.name}
                              </div>
                              <div className="text-gray-600">{`${testimonial.author.handle}`}</div>
                            </div>
                          </figcaption>
                        </figure>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
