import CustomLink from "components/Link";
import { Container } from "components/container";
import Layout from "fragments/layout/layout";
import React from "react";

export default function TermsAndConditions() {
  return (
    <Layout title="Terms and Conditions">
      <Container>
        <div className="my-32 max-w-4xl px-2">
          <h2 className="text-lg font-semibold text-primary">CLEVERNATION</h2>
          <h3 className="mt-2 text-2xl font-bold leading-8 tracking-tight text-gray-900 sm:text-3xl">
            Terms And Conditions
          </h3>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              1. About the Website
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                1.1. Welcome to{" "}
                <CustomLink child={"clevernation.com"} link="/"></CustomLink>.
                The Website provides cloud-based software that helps people
                develop their ideas into potentially successful businesses.
              </p>
              <p className="mt-4 text-gray-500">
                1.2. The Website is operated by Clevernation PTY. LTD. Access to
                and use of the Website, or any of its associated Products or
                Services, is provided by Clevernation. Please read these terms
                and conditions carefully. By using, browsing and/or reading the
                Website, this signifies that you have read, understood and agree
                to be bound by the Terms. If you do not agree with the Terms,
                you must cease usage of the Website, or any of Services,
                immediately.
              </p>
              <p className="mt-4 text-gray-500">
                1.3. Clevernation reserves the right to review and change any of
                the Terms by updating this page at its sole discretion. When
                Clevernation updates the Terms, it will use reasonable
                endeavours to provide you with notice of updates to the Terms.
                Any changes to the Terms take immediate effect from the date of
                their publication. Before you continue, we recommend you keep a
                copy of the Terms for your records.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              2. Acceptance of the Terms
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                You accept the Terms by remaining on the Website. You may also
                accept the Terms by clicking to accept or agree to the Terms
                where this option is made available to you by Clevernation in
                the user interface.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              3. Subscription to use the Services
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                3.1. In order to access the Services, you must first purchase a
                subscription through the Website and pay the applicable fee for
                the selected Subscription.
              </p>
              <p className="mt-4 text-gray-500">
                3.2. In purchasing the Subscription, you acknowledge and agree
                that it is your responsibility to ensure that the Subscription
                you elect to purchase is suitable for your use.
              </p>
              <p className="mt-4 text-gray-500">
                3.3. Once you have purchased the Subscription, you will then be
                required to register for an account through the Website before
                you can access the Services.
              </p>
              <p className="mt-4 text-gray-500">
                3.4. As part of the registration process, or as part of your
                continued use of the Services, you may be required to provide
                personal information about yourself (such as identification or
                contact details), including:
              </p>
              <div className="ml-4">
                <p className="mt-2 text-gray-500">(a) Email address</p>
                <p className="mt-2 text-gray-500">(b) Preferred username</p>
                <p className="mt-2 text-gray-500">(c) Password</p>
              </div>

              <p className="mt-4 text-gray-500">
                3.5. You warrant that any information you give to Clevernation
                in the course of completing the registration process will always
                be accurate, correct and up to date.
              </p>
              <p className="mt-4 text-gray-500">
                3.6. Once you have completed the registration process, you will
                be a registered member of the Website (&#39;Member&#39;) and
                agree to be bound by the Terms. As a Member you will be granted
                immediate access to the Services from the time you have
                completed the registration process until the subscription period
                expires (the &#39;Subscription Period&#39;).
              </p>
              <p className="mt-4 text-gray-500">
                3.7. You may not use the Services and may not accept the Terms
                if:
              </p>

              <div className="ml-4">
                <p className="mt-2 text-gray-500">
                  (a) you are not of legal age to form a binding contract with
                  Clevernation; or
                </p>
                <p className="mt-2 text-gray-500">
                  (b) you are a person barred from receiving the Services under
                  the laws of Australia or other countries including the country
                  in which you are resident or from which you use the Services.
                </p>
              </div>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              4. Your obligations as a Member
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                4.1. As a Member, you agree to comply with the following:
              </p>
              <div className="ml-4">
                <p className="mt-2 text-gray-500">
                  (a) You will use the Services only for purposes that are
                  permitted by:
                </p>
                <div className="ml-4">
                  <p className="mt-2 text-gray-500">(i) The Terms</p>
                  <p className="mt-2 text-gray-500">
                    (ii) Any applicable law, regulation or generally accepted
                    practices or guidelines in the relevant jurisdictions.
                  </p>
                </div>
                <p className="mt-2 text-gray-500">
                  (b) You have the sole responsibility for protecting the
                  confidentiality of your password and/or email address. Use of
                  your password by any other person may result in the immediate
                  cancellation of the Services.
                </p>
                <p className="mt-2 text-gray-500">
                  (c) Any use of your registration information by any other
                  person, or third parties, is strictly prohibited. You agree to
                  immediately notify Clevernation of any unauthorised use of
                  your password or email address or any breach of security of
                  which you have become aware.
                </p>
                <p className="mt-2 text-gray-500">
                  (d) Access and use of the Website is limited, non-transferable
                  and allows for the sole use of the Website by you for the
                  purposes of Clevernation providing the Services.
                </p>
                <p className="mt-2 text-gray-500">
                  (e) You will not use the Services or the Website in connection
                  with any commercial endeavours except those that are
                  specifically endorsed or approved by the management of
                  Clevernation.
                </p>
                <p className="mt-2 text-gray-500">
                  (f) You will not use the Services or Website for any illegal
                  and/or unauthorised use which includes collecting email
                  addresses of Members by electronic or other means for the
                  purpose of sending unsolicited email or unauthorised framing
                  of or linking to the Website.
                </p>
                <p className="mt-2 text-gray-500">
                  (g) You agree that commercial advertisements, affiliate links,
                  and other forms of solicitation may be removed from the
                  Website without notice and may result in termination of the
                  Services. Appropriate legal action will be taken by
                  Clevernation for any illegal or unauthorised use of the
                  Website.
                </p>
                <p className="mt-2 text-gray-500">
                  (h) You acknowledge and agree that any automated use of the
                  Website or its Services is prohibited.
                </p>
              </div>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              5. Payment
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                5.1. Where the option is given to you, you may make payment of
                the Subscription Fee by way of:
              </p>
              <div className="ml-4">
                <p className="mt-2 text-gray-500">(a) PayPal</p>
              </div>
              <p className="mt-4 text-gray-500">
                5.2. All payments made in the course of your use of the Services
                are made using Paypal. In using the Website, the Services or
                when making any payment in relation to your use of the Services,
                you warrant that you have read, understood and agree to be bound
                by the Paypal terms and conditions which are available on their
                website.
              </p>
              <p className="mt-4 text-gray-500">
                5.3. You acknowledge and agree that where a request for the
                payment of the Subscription Fee is returned or denied, for
                whatever reason, by your financial institution or is unpaid by
                you for any other reason, then you are liable for any costs,
                including banking fees and charges, associated with the
                Subscription Fee.
              </p>
              <p className="mt-4 text-gray-500">
                5.4. You agree and acknowledge that Clevernation can vary the
                Subscription Fee at any time and that the varied Subscription
                Fee will come into effect following the conclusion of the
                existing Subscription Period.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              6. Refund Policy
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                Clevernation will only provide you with a refund of the
                Subscription Fee in the event they are unable to continue to
                provide the Services or if the manager of Clevernation makes a
                decision, at its absolute discretion, that it is reasonable to
                do so under the circumstances. Where this occurs, the refund
                will be in the proportional amount of the Subscription Fee that
                remains unused by the Member.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              7. Copyright and Intellectual Property
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                7.1. The Website, the Services and all of the related products
                of Clevernation are subject to copyright. The material on the
                Website is protected by copyright under the laws of Australia
                and through international treaties. Unless otherwise indicated,
                all rights (including copyright) in the Services and compilation
                of the Website (including but not limited to text, graphics,
                logos, button icons, video images, audio clips, Website, code,
                scripts, design elements and interactive features) or the
                Services are owned or controlled for these purposes and are
                reserved by Clevernation or its contributors.
              </p>
              <p className="mt-4 text-gray-500">
                7.2. All trademarks, service marks and trade names are owned,
                registered and/or licensed by Clevernation, who grants to you a
                worldwide, non-exclusive, royalty-free, revocable license whilst
                you are a Member to:
              </p>

              <div className="ml-4">
                <p className="mt-2 text-gray-500">
                  (a) Use the Website pursuant to the Terms.
                </p>
                <p className="mt-2 text-gray-500">
                  (b) copy and store the Website and the material contained in
                  the Website in your device&#39;s cache memory.
                </p>
                <p className="mt-2 text-gray-500">
                  (c) print pages from the Website for your own personal and
                  non-commercial use.
                </p>
              </div>
              <p className="mt-4 text-gray-500">
                Clevernation does not grant you any other rights whatsoever in
                relation to the Website or the Services. All other rights are
                expressly reserved by Clevernation.
              </p>
              <p className="mt-4 text-gray-500">
                7.3. Clevernation retains all rights, title and interest in and
                to the Website and all related Services. Nothing you do on or in
                relation to the Website will transfer any:
              </p>
              <div className="ml-4">
                <p className="mt-2 text-gray-500">
                  (a) Business name, trading name, domain name, trade mark,
                  industrial design, patent, registered design or copyright.
                </p>
                <p className="mt-2 text-gray-500">
                  (b) A right to use or exploit a business name, trading name,
                  domain name, trade mark or industrial design.
                </p>
                <p className="mt-2 text-gray-500">
                  (c) A thing, system or process that is the subject of a
                  patent, registered design or copyright (or an adaptation or
                  modification of such a thing, system or process).
                </p>
              </div>
              <p className="mt-4 text-gray-500">
                7.4. You may not, without the prior written permission of
                Clevernation and the permission of any other relevant rights
                owners: broadcast, republish, up-load to a third party,
                transmit, post, distribute, show or play in public, adapt or
                change in any way the Services or third- party Services for any
                purpose, unless otherwise provided by these Terms. This
                prohibition does not extend to materials on the Website, which
                are freely available for re-use or are in the public domain.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              8. Privacy
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                8.1. Clevernation takes your privacy seriously and any
                information provided through your use of the Website and/or
                Services are subject to Clevernation &#39;s Privacy Policy,
                which is available on the Website.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              9. General Disclaimer
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                9.1. Nothing in the Terms limits or excludes any guarantees,
                warranties, representations or conditions implied or imposed by
                law, including the Australian Consumer Law (or any liability
                under them) which by law may not be limited or excluded.
              </p>
              <p className="mt-4 text-gray-500">
                9.2. Subject to this clause, and to the extent permitted by law:
              </p>

              <div className="ml-4">
                <p className="mt-2 text-gray-500">
                  (a) All terms, guarantees, warranties, representations or
                  conditions which are not expressly stated in the Terms are
                  excluded.
                </p>
                <p className="mt-2 text-gray-500">
                  (b) Clevernation will not be liable for any special, indirect
                  or consequential loss or damage (unless such loss or damage is
                  reasonably foreseeable resulting from our failure to meet an
                  applicable Consumer Guarantee), loss of profit or opportunity,
                  or damage to goodwill arising out of or in connection with the
                  Services or these Terms (including as a result of not being
                  able to use the Services or the late supply of the Services),
                  whether at common law, under contract, tort (including
                  negligence), in equity, pursuant to statute or otherwise.
                </p>
              </div>

              <p className="mt-4 text-gray-500">
                9.3. Use of the Website and the Services is at your own risk.
                Everything on the warranty or condition of any kind. None of the
                affiliates, directors, officers, employees, agents, contributors
                and licensors of Clevernation make any express or implied
                representation or warranty about the Services or any products or
                Services (including the products or Services of Clevernation)
                referred to on the Website. includes (but is not restricted to)
                loss or damage you might suffer as a result of any of the
                following:
              </p>

              <div className="ml-4">
                <p className="mt-2 text-gray-500">
                  (a) Failure of performance, error, omission, interruption,
                  deletion, defect, failure to correct defects, delay in
                  operation or transmission, computer virus or other harmful
                  component, loss of data, communication line failure, unlawful
                  third-party conduct, or theft, destruction, alteration or
                  unauthorised access to records.
                </p>
                <p className="mt-2 text-gray-500">
                  (b) The accuracy, suitability or currency of any information
                  on the Website, the Services, or any of its Services related
                  products (including third party material and advertisements on
                  the Website).
                </p>
                <p className="mt-2 text-gray-500">
                  (c) Costs incurred as a result of you using the Website, the
                  Services or any of the products of Clevernation.
                </p>
                <p className="mt-2 text-gray-500">
                  (d) The Services or operation in respect to links which are
                  provided for your convenience.
                </p>
              </div>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              10. Limitation of liability
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                10.1. Clevernation&#39;s total liability arising out of or in
                connection with the Services or these Terms, however arising,
                including under contract, tort (including negligence), in
                equity, under statute or otherwise, will not exceed the resupply
                of the Services to you.
              </p>

              <p className="mt-4 text-gray-500">
                10.2. You expressly understand and agree that Clevernation, its
                affiliates, employees, agents, contributors and licensors shall
                not be liable to you for any direct, indirect, incidental,
                special consequential or exemplary damages which may be incurred
                by you, however caused and under any theory of liability. This
                shall include, but is not limited to, any loss of profit
                (whether incurred directly or indirectly), any loss of goodwill
                or business reputation and any other intangible loss.
              </p>

              <p className="mt-4 text-gray-500">
                10.3. Our service may include links to external third-party
                websites. We have no influence on the contents of those
                websites, therefore we cannot guarantee their contents.
                Providers or administrators of linked websites are entirely
                responsible for their own content. The linked websites were
                checked for possible violations of law at the time of the
                establishment of the link. Illegal contents were not detected at
                the time of the linking. Clevernation does not and will not
                permanently monitor the contents of linked websites and will not
                be liable for any actions where there is legal violation by
                third party sites. Illegal links will be removed immediately as
                soon as we know about them.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              11. Termination of Contract
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                11.1. The Terms will continue to apply until terminated by
                either you or by Clevernation as set out below.
              </p>
              <p className="mt-4 text-gray-500">
                11.2. If you want to terminate the Terms, you may do so by:
              </p>
              <p className="mt-4 text-gray-500">
                Your notice should be sent, in writing, to Clevernation via the
                &#39;Contact Us&#39; link on our homepage.
              </p>

              <div className="ml-4">
                <p className="mt-2 text-gray-500">
                  (a) Not renewing the Subscription prior to the end of the
                  Subscription Period.
                </p>
                <p className="mt-2 text-gray-500">
                  (b) Providing Clevernation with 21 days&#39; notice of your
                  intention to terminate.
                </p>
                <p className="mt-2 text-gray-500">
                  (c) Closing your accounts for all of the services which you
                  use, where Clevernation has made this option available to you.
                </p>
              </div>

              <p className="mt-4 text-gray-500">
                11.3. Clevernation may at any time, terminate the Terms with you
                if:
              </p>
              <div className="ml-4">
                <p className="mt-2 text-gray-500">
                  (a) You do not renew the Subscription at the end of the
                  Subscription Period.
                </p>
                <p className="mt-2 text-gray-500">
                  (b) You have breached any provision of the Terms or intend to
                  breach any provision.
                </p>
                <p className="mt-2 text-gray-500">
                  (c) Clevernation is required to do so by law.
                </p>
                <p className="mt-2 text-gray-500">
                  (d) The provision of the Services to you by Clevernation is,
                  in the opinion of Clevernation, no longer commercially viable.
                </p>
              </div>

              <p className="mt-4 text-gray-500">
                11.4. Subject to local applicable laws, Clevernation reserves
                the right to discontinue or cancel your membership at any time
                and may suspend or deny, in its sole discretion, your access to
                all or any portion of the Website or the Services without notice
                if you breach any provision of the Terms or any applicable law
                or if your conduct impacts Clevernation &#39;s name or
                reputation or violates the rights of those of another party.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              12. Indemnity
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                12.1. You agree to indemnify Clevernation, its affiliates,
                employees, agents, contributors, third party content providers
                and licensors from and against:
              </p>
              <div className="ml-4">
                <p className="mt-2 text-gray-500">
                  (a) All actions, suits, claims, demands, liabilities, costs,
                  expenses, loss and damage (including legal fees on a full
                  indemnity basis) incurred, suffered or arising out of or in
                  connection with Your Content.
                </p>
                <p className="mt-2 text-gray-500">
                  (b) Any direct or indirect consequences of you accessing,
                  using or transacting on the Website or attempts to do so.
                </p>
                <p className="mt-2 text-gray-500">
                  (c) Any breach of the Terms.
                </p>
              </div>

              <p className="mt-4 text-gray-500"></p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              13. Dispute Resolution
            </h2>
            <div className="ml-4">
              <div className="my-4">
                <h3 className="text-lg font-bold tracking-tight text-primary">
                  13.1. Compulsory:
                </h3>
                <div className="ml-4">
                  <p className="mt-2 text-gray-500">
                    If a dispute arises out of or relates to the Terms, either
                    party may not commence any Tribunal or Court proceedings in
                    relation to the dispute, unless the following clauses have
                    been complied with (except where urgent interlocutory relief
                    is sought).
                  </p>
                </div>
              </div>

              <div className="my-4">
                <h3 className="text-lg font-bold tracking-tight text-primary">
                  13.2. Notice:
                </h3>
                <div className="ml-4">
                  <p className="mt-2 text-gray-500">
                    A party to the Terms claiming a dispute (&#39;Dispute&#39;)
                    has arisen under the Terms, must give written notice to the
                    other party detailing the nature of the dispute, the desired
                    outcome and the action required to settle the Dispute.
                  </p>
                </div>
              </div>

              <div className="my-4">
                <h3 className="text-lg font-bold tracking-tight text-primary">
                  13.3. Resolution:
                </h3>
                <div className="ml-4">
                  <p className="mt-2 text-gray-500">
                    On receipt of that notice (&#39;Notice&#39;) by that other
                    party, the parties to the Terms (&#39;Parties&#39;) must:
                  </p>
                  <div className="ml-4">
                    <p className="mt-2 text-gray-500">
                      (a) Within 21 days of the Notice endeavour in good faith
                      to resolve the Dispute expeditiously by negotiation or
                      such other means upon which they may mutually agree.
                    </p>
                    <p className="mt-2 text-gray-500">
                      (b) If for any reason whatsoever, 21 days after the date
                      of the Notice, the Dispute has not been resolved, the
                      Parties must either agree upon selection of a mediator or
                      request that an appropriate mediator be appointed by the
                      President of the Australian Mediation Association or his
                      or her nominee.
                    </p>
                    <p className="mt-2 text-gray-500">
                      (c) The Parties are equally liable for the fees and
                      reasonable expenses of a mediator and the cost of the
                      venue of the mediation and without limiting the foregoing
                      undertake to pay any amounts requested by the mediator as
                      a pre-condition to the mediation commencing. The Parties
                      must each pay their own costs associated with the
                      mediation.
                    </p>
                    <p className="mt-2 text-gray-500">
                      (d) The mediation will be held in Sydney, Australia.
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-4">
                <h3 className="text-lg font-bold tracking-tight text-primary">
                  13.4. Confidential:
                </h3>
                <div className="ml-4">
                  <p className="mt-2 text-gray-500">
                    All communications concerning negotiations made by the
                    Parties arising out of and in connection with this dispute
                    resolution clause are confidential and to the extent
                    possible, must be treated as &quot;without prejudice&quot;
                    negotiations for the purpose of applicable laws of evidence.
                  </p>
                </div>
              </div>

              <div className="my-4">
                <h3 className="text-lg font-bold tracking-tight text-primary">
                  13.5. Termination of Mediation:
                </h3>
                <div className="ml-4">
                  <p className="mt-2 text-gray-500">
                    If 1 month have elapsed after the start of a mediation of
                    the Dispute and the Dispute has not been resolved, either
                    Party may ask the mediator to terminate the mediation and
                    the mediator must do so.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              14. Venue and Jurisdiction
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                The Services offered by Clevernation is intended to be viewed by
                residents of Australia. In the event of any dispute arising out
                of or in relation to the Website, you agree that the exclusive
                venue for resolving any dispute shall be in the courts of New
                South Wales, Australia.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              15. Governing Law
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                The Terms are governed by the laws of New South Wales,
                Australia. Any dispute, controversy, proceeding or claim of
                whatever nature arising out of or in any way relating to the
                Terms and the rights created hereby shall be governed,
                interpreted and construed by, under and pursuant to the laws of
                New South Wales, Australia, without reference to conflict of law
                principles, notwithstanding mandatory rules. The validity of
                this governing law clause is not contested. The Terms shall be
                binding to the benefit of the parties hereto and their
                successors and assigns.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              16. Independent Legal Advice
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                Both parties confirm and declare that the provisions of the
                Terms are fair and reasonable and both parties having taken the
                opportunity to obtain independent legal advice and declare the
                Terms are not against public policy on the grounds of inequality
                or bargaining power or general grounds of restraint of trade.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              17. Severance
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                If any part of these Terms is found to be void or unenforceable
                by a Court of competent jurisdiction, that part shall be severed
                and the rest of the Terms shall remain in force.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              18.Additional User Generated Content (UGC) Compliance Terms:
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                18.1. Users contributing content to the app, including but not
                limited to profiles, comments, posts, etc. ("User-Generated
                Content" or "UGC"), must adhere to our moderation policies.
              </p>
              <p className="mt-4 text-gray-500">
                18.2. We reserve the right to moderate, monitor, and remove any
                UGC that violates our content policies, as well as Google Play's
                policies.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              19.Moderation Tools and Features:
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                19.1. Our app incorporates effective moderation tools and
                features to identify and handle objectionable UGC.
              </p>
              <p className="mt-4 text-gray-500">
                19.2. Users are encouraged to report and flag content that may
                violate our policies, and we will promptly review and address
                such reports.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              20.Terms of Service (ToS) and User Policies:
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                20.1. Users are required to accept our app's Terms of Service
                (ToS) and/or user policy before creating or uploading UGC
              </p>
              <p className="mt-4 text-gray-500">
                20.2. The ToS and/or user policy define objectionable content
                and behaviors, ensuring compliance with Google Play's Developer
                Program policies.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              21.Access to Terms of Service:
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                21.1. Our app provides an easily accessible and clear link to
                the Terms of Service and/or user policy within the application.
              </p>
              <p className="mt-4 text-gray-500">
                21.2. The Terms of Service and/or user policy outline the
                guidelines for user conduct and content creation.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              22.Reporting and Flagging Mechanisms:{" "}
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                22.1. Users can report and flag potentially violating content
                through designated mechanisms within the app.
              </p>
              <p className="mt-4 text-gray-500">
                22.2. The reporting and flagging functionalities serve multiple
                moderation purposes and are designed to be clear and
                user-friendly.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              23.Compliance with Google Play Policies:
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                23.1. We acknowledge and comply with Google Play's User
                Generated Content (UGC) policies, as outlined in their official
                documentation.
              </p>
              <p className="mt-4 text-gray-500">
                23.2. Any failure to adhere to these policies may result in the
                removal of our app from the Play Store.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              24.Privacy Policy Distinction:
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                24.1. While a Privacy Policy is maintained, it is explicitly
                stated that it is not a substitute for the Terms of Service
                regarding User-Generated Content.
              </p>
            </div>
          </div>

          <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary">
              25.Continuous Review and Updates:
            </h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500">
                25.1. We commit to continuously reviewing and updating our UGC
                policies to align with Google Play's guidelines and industry
                best practices.
              </p>
              <p className="mt-4 text-gray-500">
                25.2. Users are encouraged to check for updates to our policies
                regularly.
              </p>
            </div>
          </div>

          {/* <div className="my-8">
            <h2 className="text-xl font-bold tracking-tight text-primary"></h2>
            <div className="ml-4">
              <p className="mt-4 text-gray-500"></p>
            </div>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
