import { Button, TextField } from "@mui/material";
import Layout from "fragments/layout/layout";
import MuiPhoneNumber from "material-ui-phone-number";
import React from "react";
import { AiFillRedEnvelope } from "react-icons/ai";
import { HiOfficeBuilding, HiPhoneIncoming } from "react-icons/hi";

export function Contact() {
  return (
    <Layout title="Contact us">
      <div className="relative isolate bg-white">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-4 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                <svg
                  className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  aria-hidden="true">
                  <defs>
                    <pattern
                      id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                      width={200}
                      height={200}
                      x="100%"
                      y={-1}
                      patternUnits="userSpaceOnUse">
                      <path d="M130 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                  </defs>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="white"
                  />
                  <svg
                    x="100%"
                    y={-1}
                    className="overflow-visible fill-gray-50">
                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                  </svg>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                  />
                </svg>
              </div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                Get in
                <span className="text-primary font-bold"> Touch</span>
              </h2>
              {/* <p className="mt-6 text-lg leading-8 text-gray-600">
                Proin volutpat consequat porttitor cras nullam gravida at. Orci
                molestie a eu arcu. Sed ut tincidunt integer elementum id sem.
                Arcu sed malesuada et magna.
              </p> */}
              <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                <div className="flex gap-x-4 hover:text-primary cursor-pointer">
                  <dt className="flex-none">
                    <span className="sr-only">Address</span>
                    <HiOfficeBuilding className="h-7 w-6" aria-hidden="true" />
                  </dt>
                  <dd>Sydney, Australia</dd>
                </div>
                {/* <div className="flex gap-x-4 hover:text-primary cursor-pointer">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <HiPhoneIncoming className="h-7 w-6" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a href="tel:+197989389898">+1 1212186490</a>
                  </dd>
                </div> */}
                <div className="flex gap-x-4 hover:text-primary cursor-pointer">
                  <dt className="flex-none">
                    <span className="sr-only">Email</span>
                    <AiFillRedEnvelope className="h-7 w-6" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a href="mailto:contact@Clevernation.com">contact@Clevernation.com</a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <form
            action="#"
            method="POST"
            className="px-4 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <TextField label="First Name" />
                <TextField label="Last Name" />

                <div className="sm:col-span-2">
                  <TextField label="Email" fullWidth />
                </div>
                <div className="sm:col-span-2">
                  <MuiPhoneNumber
                    defaultCountry={"us"}
                    variant="outlined"
                    label="Phone Number"
                    fullWidth
                  />
                </div>
                <div className="sm:col-span-2">
                  <TextField
                    label="Phone Number"
                    multiline
                    rows="6"
                    type="phone"
                    fullWidth
                  />
                </div>
              </div>
              <div className="mt-8 flex justify-end">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ color: "white" }}>
                  Send Message
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
