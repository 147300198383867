import { BsCake2 } from "react-icons/bs";

export default function WhoWeAre() {
  return (
    <div className="overflow-hidden my-14">
      <div>
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            {/* <h2 className="text-lg font-semibold text-indigo-600">
              Case Study
            </h2> */}
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Who <span className="text-primary">We Are</span>
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true">
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
                  <img
                    className="rounded-lg object-cover object-center shadow-lg"
                    src="/media/playingg.jpeg"
                    alt=""
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-gray-500"></p>
            </div>
            <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <div className="mt-8 lg:mt-0">
                <div className="max-w-xl">
                  <h3 className="flex mt-6 text-primary items-center gap-x-3 text-lg font-semibold leading-7">
                    Igniting Transformation in Sports
                  </h3>
                  <p className="mt-2">
                    At Clevernation, we're not just passionate about igniting
                    transformation and driving progress in the sporting
                    industry. We're confident in our ability to inspire change
                    that can transform the world of sports.
                  </p>
                  <h3 className="flex text-primary items-center mt-6 text-lg font-semibold leading-7">
                    Unleashing Potential
                  </h3>
                  <p className="mt-2">
                    We're the driving force behind the transformation of clubs
                    and academies, igniting a fire of excellence within every
                    athlete. With us, athletes can achieve their full potential
                    and reach new heights of success.
                  </p>

                  <h3 className="flex text-primary items-center mt-6 text-lg font-semibold leading-7">
                    Empowering Progress, Inspiring Triumph
                  </h3>
                  <p className="mt-2">
                    Through our pioneering platform, Clevernation, we're redefining
                    how progress is measured and providing athletes with the
                    tools they need to achieve their goals. With data-driven
                    insights and continuous support, we empower athletes to
                    track their progress and achieve triumph beyond their
                    wildest dreams.
                  </p>

                  <h3 className="flex text-primary items-center mt-6 text-lg font-semibold leading-7">
                    A Symphony of Growth
                  </h3>
                  <p className="mt-2">
                    Our approach centres on listening to our customers and
                    creating tailored solutions that meet genuine needs. By
                    working together, we can create a symphony of growth that
                    leads to success for every athlete. Join us in igniting
                    transformation and unleashing potential in the world of
                    sports. Together, we can inspire change and shape the future
                    of sports.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
