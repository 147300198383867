import React from "react";
import { Container } from "components/container";

const features = [
  {
    name: "Transparent Progress Tracking",
    description:
      "Football associations, clubs, and academies can now seamlessly track and share player progress, identifying strengths, weaknesses, and tailored training needs.",
  },
  {
    name: "Promotion of Development Philosophy",
    description:
      "Our platform acts as a catalyst for promoting and educating players about the philosophy behind their development plans.",
  },
  {
    name: "Player Profiling",
    description:
      "From grassroots to higher levels, our platform supports player profiling, providing clarity on skill sets required for progression.",
  },
  {
    name: "Time-Efficient Assessments",
    description:
      "Coaches no longer have to be concerned about time-consuming assessment reports. With AI integration, assessments are streamlined, allowing for more focused coaching.",
  },
];

export function FeaturesPlatform() {
  return (
    <Container>
      <div className="bg-white">
        <section aria-labelledby="features-heading" className="relative">
          <div className="aspect-h-1 aspect-w-3 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-16">
            <img
              src="/media/foots.jpeg"
              alt="hjk."
              className="h-full rounded-xl w-full object-cover object-center lg:h-ful lg:w-full"
            />
          </div>

          <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32">
            <div className="lg:col-start-2">
              {/* <h2 id="features-heading" className="font-medium text-gray-500">
                Leatherbound Daily Journal
              </h2> */}
              <p className="mt-4 text-4xl font-bold tracking-tight text-gray-900">
                <span className="text-primary">Revolutionising </span>
                Player Development
              </p>
              {/* <p className="mt-4 text-gray-500">
                We've obsessed over every detail of this handcrafted journal to
                bring you the best materials for daily use.
              </p> */}

              <dl className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 text-sm sm:grid-cols-2">
                {features.map((feature) => (
                  <div key={feature.name}>
                    <dt className="font-medium text-medium text-gray-900">
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-gray-500">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </section>
      </div>
    </Container>
  );
}
