import { Helmet } from "react-helmet";
import { Header } from "fragments/header";
import { Footer } from "fragments/footer";

export default function Layout({ children, title }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header />
      <div className="min-h-[50vh] mt-[32px]">{children}</div>
      <Footer />
    </>
  );
}

Layout.defaultProps = {
  title: "Clevernation",
  description: "",
  keywords: "noindex, nofollow",
};
