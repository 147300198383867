import { Popover } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

import { Container } from "components/container";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { Button } from "@mui/material";
import { COLORS } from "constants/colors";

export function Header() {
  return (
    <header className="w-full">
      <nav className="backdrop-blur-md bg-white/75 shadow-1 z-[99999] fixed w-full top-0">
        <Container className="z-50 flex justify-between py-4">
          <div className="relative z-10 flex items-center gap-16">
            <Link to="/" aria-label="Home">
              <img src="/logo.png" className="w-[150px]" alt="" />
            </Link>
          </div>
          <div className="flex items-center gap-6">
            <div className="hidden lg:flex lg:gap-4">
              <Link to="/">
                <Button>Home</Button>
              </Link>
              <Link to="/about">
                <Button>About us</Button>
              </Link>
              <Link to="/platform">
                <Button>Our Platform</Button>
              </Link>
              <Link to="/success-story">
                <Button>Success Stories</Button>
              </Link>
              <Link to="/case-study">
                <Button>Case Study</Button>
              </Link>
              <Link to="/contact">
                <Button
                  variant="contained"
                  sx={{ color: "white" }}
                  className="hidden lg:block">
                  Contact us
                </Button>
              </Link>
            </div>

            <Popover className="lg:hidden">
              {({ open }) => (
                <>
                  <Popover.Button
                    className="relative z-10 bg-white -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 ui-not-focus-visible:outline-none"
                    aria-label="Toggle site navigation">
                    {({ open }) =>
                      open ? (
                        <AiOutlineClose className="h-6 w-6 text-primary" />
                      ) : (
                        <HiOutlineMenuAlt4 className="h-6 w-6 text-primary" />
                      )
                    }
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -32 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: { duration: 0.2 },
                          }}
                          className="absolute inset-x-0 bg-gray top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20">
                          <div className="space-y-4 flex flex-col">
                            <Link to="/">Home</Link>
                            <Link to="/about">About us</Link>
                            <Link to="/platform">Our Platform</Link>
                            <Link to="/success-story">Success Stories</Link>
                            <Link to="/case-study">Case Study</Link>
                          </div>
                          <Link
                            to="/contact"
                            className="mt-8 flex flex-col gap-4">
                            <Button
                              variant="contained"
                              sx={{ color: COLORS?.secondary }}>
                              Contact
                            </Button>
                          </Link>
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>
          </div>
        </Container>
      </nav>
    </header>
  );
}
