import { Container } from "components/container";
import Layout from "fragments/layout/layout";
import { BsCamera } from "react-icons/bs";

export function CaseStudy() {
  return (
    <Layout title="Case Study">
      {/* hero */}
      <div className="overflow-hidden my-24 bg-white">
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-16">
          <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-50 lg:block" />
          <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
            <div>
              <h2 className="text-lg font-semibold text-primary">Case Study</h2>
              <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                Transforming Youth Development at an Elite Football Academy
              </h3>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:col-start-2 lg:row-start-1">
              <svg
                className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true">
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse">
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                <figure>
                  <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
                    <img
                      className="rounded-lg object-cover object-center shadow-lg"
                      src="/media/playingg.jpeg"
                      alt="Whitney leaning against a railing on a downtown street"
                      width={1184}
                      height={1376}
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="mx-auto max-w-prose lg:max-w-none">
                <div className="mb-8">
                  <h2 className="text-2xl font-bold tracking-tight text-primary">
                    Introduction
                  </h2>
                  <p className="mt-4 text-gray-500">
                    At Clevernation, we envision a future where athletes have
                    access to the tools, guidance, and unwavering support
                    necessary to excel. Our platform is designed to inspire and
                    enable athletes to take control of their development,
                    guiding them toward their full potential.
                  </p>
                </div>
                <div className="">
                  <h2 className="text-2xl font-bold tracking-tight text-primary">
                    Client Background
                  </h2>
                  <p className="mt-4 text-gray-500">
                    Our client is a prominent, elite football club that has
                    consistently produced talented players who have gone on to
                    succeed at both national and international levels. However,
                    the club recognised the need to modernise its academy
                    operations and adapt to the ever-evolving landscape of
                    football development.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container>
        {/* challenges */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-8 mb-24 border-b pb-24 pt-12">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-primary">
              Challenges
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-500">
              The club faced several challenges in its youth development
              program:
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  1. Manual Report Writing:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  Coaches spent an excessive amount of time manually creating
                  player development reports, impacting their ability to focus
                  on coaching and player improvement.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  2. Data Management:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  The academy had accumulated a vast amount of player data over
                  the years, making it challenging to identify trends and areas
                  for improvement.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  3. Limited Resources:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  The club's academy had limited resources, and optimising
                  coaching staff time and player development was essential to
                  maximise the return on investment.
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Solutions */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-primary">
              Solutions
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              The club partnered with Clevernation to implement a cloud-based
              AI-powered player development solution tailored to their specific
              needs. The solution offered the following features:
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  1. Automated Report Generation:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  The software utilised AI algorithms to analyse player
                  performance data and generate comprehensive development
                  reports for each player automatically.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  2. Data Integration:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  The software seamlessly integrated with the club's existing
                  data sources, including training logs, match statistics, and
                  physical assessments, providing a centralised database for
                  analysis.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  3. Performance Analytics:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  Advanced analytics tools allowed coaches to identify strengths
                  and weaknesses in individual player development, track
                  progress over time, and make data-driven decisions.
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Implementation and Impact */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-8 mt-24 border-t pt-24">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-primary">
              Implementation and Impact:
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-500">
              The implementation of the cloud-based AI-powered player
              development solution had a transformative impact on the club's
              academy:
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  1. Time Efficiency:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  Coaches no longer had to spend hours manually compiling
                  reports. This timesaving allowed them to focus on delivering
                  high-quality coaching sessions and providing personalised
                  feedback to players.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  2. Data-Driven Decisions:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  With access to comprehensive player development data, coaching
                  staff could identify trends and areas for improvement,
                  resulting in more effective training programs and player
                  development plans.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  3. Improved Player Development:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  Players received more personalised coaching and feedback,
                  resulting in accelerated skill development. The club saw a
                  noticeable improvement in the performance of academy players.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  4. Resource Optimization:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  The software helped the club optimise coaching staff time and
                  resources, enabling them to allocate resources more
                  efficiently and effectively.
                </dd>
              </div>
              <div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  5. Competitive Edge:
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  With a more streamlined and data-driven youth development
                  program, the club gained a competitive edge in recruiting and
                  developing top talent, contributing to the success of their
                  senior team.
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="mt-12 mb-24">
          <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-primary sm:text-4xl">
            Conclusion:
          </h3>
          <p className="mt-2 text-gray-500">
            The adoption of Clevernation, a cloud-based AI-powered player
            development solution has transformed the youth development program
            of this elite football club. By automating report generation,
            centralising player data, and providing advanced analytics tools,
            the club has improved coaching efficiency, player development, and
            overall academy performance. This case study demonstrates the
            potential for AI-driven solutions to revolutionise sports
            organizations and help them stay at the forefront of their
            respective fields.
          </p>
        </div>
      </Container>
    </Layout>
  );
}
