import { CgProfile, CgSupport } from "react-icons/cg";
import { TbSchool } from "react-icons/tb";
import { MdSpatialTracking } from "react-icons/md";

const incentives = [
  {
    name: "Transparent Player Profiles",
    icon: CgProfile,
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Easily accessible player profiles allow parents and players to understand current skill levels and the roadmap for improvement.",
  },
  {
    name: "Education at Your Fingertips",
    icon: TbSchool,
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "A simple UI educates players on development topics, keeping them engaged and informed even off the field.",
  },
  {
    name: "Grassroots Support",
    icon: CgSupport,
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Local clubs play a pivotal role in player development, guiding players toward pathways like academies by utilising identified player characteristics.",
  },
  {
    name: "In-Depth Player Tracking",
    icon: MdSpatialTracking,
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Coaches use our platform to track player progress, while players and parents gain insights into requirements for skill advancement.",
  },
];

export function Incentives() {
  return (
    <div className="bg-gray-50 my-24">
      <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
          <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
            <div>
              <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                The Player and Parent{" "}
                <span className="text-primary">Advantage</span>
              </h2>
              <p className="mt-4 text-gray-500">
                The Player and Parent Advantage simplifies player development.
                We offer accessible player profiles for skill assessment, an
                easy-to-use educational platform, and support from local clubs.
                Coaches use it for tracking progress, while players and parents
                gain insights for skill improvement. It's all about helping
                players reach their full potential with informed parents.
              </p>
            </div>
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100">
              <img
                src="/media/juniors.jpeg"
                alt=""
                className="object-cover object-center"
              />
            </div>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            {incentives.map((incentive) => (
              <div key={incentive.name} className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  {/* <img className="h-16 w-16" src={incentive.imageSrc} alt="" /> */}
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-primary">
                    <incentive.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                  <h3 className="text-md font-medium text-gray-900">
                    {incentive.name}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {incentive.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
